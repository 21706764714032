const initialState = {
  courses: [],
  coursePackages: [],
  locations: [],
}

const courseReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_COURSES':
      console.log({ receivedPayload: action.payload })
      return {
        ...state,
        courses: action.payload.courses,
        coursePackages: action.payload.coursePackages,
        locations: action.payload.locations,
      }
    case 'SHOW_NOTIFY': {
      return {
        ...state,
        data: {
          type: action.payload.type,
          show: true,
          message: action.payload.message,
        },
      }
    }

    case 'HIDE_NOTIFY': {
      return {
        ...state,
        data: {
          show: false,
        },
      }
    }

    default:
      return state
  }
}
export default courseReducer
