import Axios from 'axios'
// import { constants } from 'buffer'
import Cookies from 'universal-cookie'
import configs from '../configs/configs'
import {
  ROUTES,
  AUTHENTICATION_COOKIES,
  PATH,
  DOMAIN,
  HOME_URL,
} from '../configs/constants'

const cookies = new Cookies()

console.log({ ROUTES })
var BASE_URL = configs.BASE_URL

Axios.interceptors.request.use(
  function (config) {
    config.withCredentials = true
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

let expireOlderCookies = (name, paths) => {
  for (var i = 0, l = paths.length; i < l; i++) {
    cookies.remove(name, paths[i])
  }
}
let updateURLParameter = (url, param, paramVal) => {
  var newAdditionalURL = ''
  var tempArray = url.split('?')
  var baseURL = tempArray[0]
  var additionalURL = tempArray[1]
  var temp = ''
  if (additionalURL) {
    tempArray = additionalURL.split('&')
    for (var i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split('=')[0] != param) {
        newAdditionalURL += temp + tempArray[i]
        temp = '&'
      }
    }
  }

  var tokn = temp + '' + param + '=' + paramVal
  return baseURL + '?' + newAdditionalURL + tokn
}
let removeAuthCookies = () => {
  console.log('\n:::::::: Removing Auth Cookies :::::::: ')
  for (let i = 0; i < AUTHENTICATION_COOKIES.length; i++) {
    cookies.remove(AUTHENTICATION_COOKIES[i], {
      path: PATH,
      domain: DOMAIN,
    })
    console.log(
      '\t Removed - Cookie : ',
      AUTHENTICATION_COOKIES[i],
      ', Domain : ',
      DOMAIN,
      ', Path : ',
      PATH
    )
  }
  console.log(':::::::: Removed Auth Cookies ::::::::\n')
}
Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.log('---------- Error ::: ', error, ' --------------')
    console.log({ error })
    console.log({ errorResConfig: error.response?.config })

    const originalRequest = error?.config
    console.log({ error }, { originalRequest })
    if (error.response.status === 498 && !originalRequest._retry) {
      originalRequest._retry = true // to avoid loops

      if (!error.response.config.url.includes('/g_at_w')) {
        console.log(
          ' ================= Calling /g_at_w for token renewal ============== '
        )
        try {
          // expireOlderCookies('at', ['/', '/dashboard'])
          let at_updated_tkn = await renewAccessToken()

          console.log(
            ' --- Response from /g_at_w ::: ',
            { newAtToUse: at_updated_tkn },
            ' ---- '
          )

          if (!at_updated_tkn) {
            logoutUser() // will be called when the at token is not refreshed.
            return Promise.reject(error)
          }

          // three ways to use the new at token,
          // first, in req.body.tokn (for post calls)
          // second, in req.query.tokn (for get calls)
          // third, in req.headers.x-access-token

          if (
            error.response.config.method === 'POST' ||
            error.response.config.method === 'post'
          ) {
            let dataToAdd = JSON.parse(error.response.config.data)
            error.response.config.data = {
              ...dataToAdd,
              tokn: at_updated_tkn,
            }
          } else {
            let newURlwithQueryParams = updateURLParameter(
              error.response.config.url,
              'tokn',
              at_updated_tkn
            )
            console.log({ newURlwithQueryParams })
            error.response.config.url = newURlwithQueryParams
          }

          error.response.config.headers['x-access-token'] = at_updated_tkn
          error.response.headers['x-access-token'] = at_updated_tkn

          console.log(
            '\n ---- Final API call ::: ',
            error.response.config,
            ' ---- \n'
          )

          // call the api which initially gave 498.
          return await Axios(error.response.config)
        } catch (error) {
          logoutUser() // will be called if there is an error in /g_at_w
          return Promise.resolve(error.response || error)
        }
      } else {
        logoutUser() // will be called if there is 498 issue but the route is NOT - /courselib and /g_at_w
        return Promise.resolve(error.response || error)
      }
    } else {
      // Do something with response error
      console.log('inside interceptor')
      return Promise.resolve(error.response || error)
    }
  }
)

export const renewAccessToken = (cb) => {
  console.log('g_at_w here::')
  return new Promise((resolve, reject) => {
    let opts = {
      method: 'post',
      url: ROUTES.RENEW_ACCESS_TOKEN,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        a_id: cookies.get('aid'),
        d_id: window.fingerprint.md5hash,
        at: cookies.get('at'),
        cl: 'W',
        rt: cookies.get('rt'),
        k_ey: cookies.get('secret'),
      },
    }
    console.log(JSON.stringify(opts))
    Axios(opts)
      .then((res) => {
        console.log({ data: res.data.data })
        var at = res.data.data.at
        var expiredate = new Date()
        expiredate.setDate(expiredate.getDate() + 180)
        if (at)
          cookies.set('at', at, {
            expires: expiredate,
            path: PATH,
            domain: DOMAIN,
          })
        return resolve(at)
      })
      .catch((err) => {
        console.error({ err })
        resolve(err)
        removeAuthCookies()
        window.location.reload()
      })
  })
}
const logoutUser = () => (dispatch) => {
  var data = {
    headers: {
      'Content-Type': 'application/json',
    },
    a_id: cookies.get('aid'),
    d_id: window.fingerprint.md5hash,
    at: cookies.get('at'),
    cl: 'W',
    rt: cookies.get('rt'),
    // k_ey	: utils.getSecret()
  }
  Axios.post(ROUTES.LOGOUT, data)
    .then((res) => {
      removeAuthCookies()
      var home_page_url = configs.ECKOVATION_WEB_ENDPOINT
      window.location.href = home_page_url
    })
    .catch((err) => {
      console.log({ err })
    })
}
export const fetchUserbyMobile = async ({ ccod, mobile }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUser?'
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let query = 'ccod=' + ccod + '&mobile=' + mobile
    console.log({ query })
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchUserbyMobile data!!', res.data)
          return resolve({
            success: res.data.success,
            ...res.data.data,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getAllPossibleLocations = () => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_all_course_locations?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    // query+= 'crs_pg_id=' + crs_pg_id
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('get_all_course_locations data!!', res.data)
          return resolve({
            ...res.data.data,
            success: res.data.success,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}

export var getAllUndepositedCashByLocation = ({
  locId,
  skip = 0,
  limit = 10,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/get_undeposited_cash_location?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')

    query += 'locId=' + locId
    if (aid) query += '&aid=' + aid
    if (pid) query += '&pid=' + pid
    query += '&skip=' + skip
    query += '&limit=' + limit
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllUndepositedCashByLocation data!!', res.data)
          return resolve({
            ...res.data.data,
            success: res.data.success,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var getBulkDepositsByLocation = ({ locId, skip = 0, limit = 10 }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/get_branch_collected_undeposited_cash_history?'
    let query = ''

    query += '&locId=' + locId
    query += '&skip=' + skip
    query += '&limit=' + limit
    Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getAllUndepositedCashByLocation data!!', res.data)
          return resolve({
            ...res.data.data,
            success: res.data.success,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export const createBranchBulkPayment = ({
  payments,
  locationId,
  currency,
  bankAccountNumber,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/createBranchBulkPayment'
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
      data: {
        payments,
        locationId,
        currency,
        bankAccountNumber,
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('createBranchBulkPayment data!!', res.data)
          return resolve({
            success: res.data.success,
            ...res.data.data,
            status: statusCode,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return Promise.reject(err)
      })
  })
}
export var getUserPermissions = () => {
  return new Promise((resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/cfpanelGeneric/get_own_permissions?'
    let query = ''
    let aid = cookies.get('aid')
    let pid = cookies.get('pid')
    let isLoggedIn = cookies.get('isLoggedIn')

    console.log('nimishhhhhhhhhhhhhhhhhhhhh', isLoggedIn)

    if (!isLoggedIn)
      return resolve({
        data: {
          permissionMap: {},
          permissions: { VIEW: 1, CREATE: 2, UPDATE: 3, DELETE: 4 },
          message: 'Login Again',
        },
        success: false,
        status: 400,
      })
    if (aid) query += 'aid=' + aid
    if (!query) {
      if (pid) query += 'pid=' + pid
    } else {
      if (pid) query += '&pid=' + pid
    }
    return Axios({
      method: 'get',
      url: URL + query,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUserPermissions data!!', res.data)
          return resolve({ ...res.data, success: true, status: statusCode })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log({ err })
        return resolve(err)
      })
  })
}
export var createRefundRequest = ({ trxn_id, amount, invoice_number }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/createRefundRequest'
    // let aid = cookies.get('aid')
    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at') || '',
      },
      data: {
        trxn_id,
        amount,
        invoice_number,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('createRefundRequest data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var applyCustomerCashPayment = ({
  trxnId,
  aid,
  paymentType,
  amount,
  installmentPlanId,
  emiPlanId,
  otp,
  email,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/applyCustomerPayment'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at') || '',
      },
      data: {
        trxnId,
        aid,
        paymentType,
        amount,
        installmentPlanId,
        emiPlanId,
        otp,
        email,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('applyCustomerCashPayment data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var fetchNextCustomerPaymentAmount = ({
  trxnId,
  aid,
  paymentType,
  installmentPlanId,
  emiPlanId,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getNextCustomerPaymentAmount'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at') || '',
      },
      data: {
        trxnId,
        aid,
        paymentType,
        installmentPlanId,
        emiPlanId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchNextCustomerPaymentAmount data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export const fetchCourses = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    let statusCode
    Axios({
      method: 'GET',
      url: BASE_URL + '/ops/get_all_courses',
      headers: {
        'x-access-token': cookies.get('at'),
      },
    })
      .then((res) => {
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('fetchCourses data!!', res.data)
          console.log('dispatching:: UPDATE_COURSES')
          dispatch({
            type: 'UPDATE_COURSES',
            payload: {
              courses: res.data.data.courses,
              coursePackages: res.data.data.coursePackages,
              locations: res.data.data.locations,
            },
          })
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var getUpdatedInvoices = ({ trxnId }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/getUpdatedInvoices'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at') || '',
      },
      data: {
        trxnId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('getUpdatedInvoices data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var sendOtpToApproveRefund = ({ trxn_id, refundRequestId }) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/sendOtpToApproveRefund'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at') || '',
      },
      data: {
        trxn_id,
        refundRequestId,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('sendOtpToApproveRefund data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
export var approveRefundWithOtp = ({
  trxn_id,
  refundRequestId,
  otp,
  invoice_number,
}) => {
  return new Promise(async (resolve, reject) => {
    let statusCode
    let URL = BASE_URL + '/ops/approveRefundWithOtp'

    Axios({
      method: 'post',
      url: URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': cookies.get('ops_at') || cookies.get('at') || '',
      },
      data: {
        trxn_id,
        refundRequestId,
        otp,
        invoice_number,
      },
    })
      .then((res) => {
        // console.log({ res })
        if (!res) {
          return reject(res)
        }
        console.log({ data: res.data })
        statusCode = res.status
        if (res.data.success) {
          console.log('approveRefundWithOtp data!!', res.data)
          return resolve({
            ...res.data.data,
            status: statusCode,
            success: true,
          })
        } else {
          console.log('API call failed!', res.data)
          return resolve({ ...res.data, status: statusCode })
        }
      })
      .catch((err) => {
        console.log(err)
        return reject(err)
      })
  })
}
