import { createStore, combineReducers, applyMiddleware, compose } from 'redux'
// import { legacy_createStore as createStore} from 'redux'
import thunk from 'redux-thunk'
import promise from 'redux-promise-middleware'
import loginReducer from './reducers/loginReducer'
import courseReducer from './reducers/courseReducer'
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const configureStore = () => {
  var store = createStore(
    combineReducers({
      login: loginReducer,
      course: courseReducer,
    }),
    applyMiddleware(thunk)
  )

  return store
}
export default configureStore
